// EventList.js with pagination
import React, { useState, useEffect } from 'react';
import { Grid, Box, CircularProgress, Typography, Button, Stack } from '@mui/material';
import EventCard from './EventCard';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

function EventList({ city }) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  // Reset page when city changes
  useEffect(() => {
    setPage(1);
  }, [city]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        console.log(`Fetching events for city: ${city}, page: ${page}`);
        
        // Add timestamp to prevent caching
        const timestamp = new Date().getTime();
        const url = `${apiUrl}/${city}/events/?upcoming=true&page=${page}&t=${timestamp}`;
        console.log("API URL:", url);
        
        const response = await axios.get(url);
        console.log("API response:", response);
        
        // Handle paginated response
        if (response.data && response.data.results && Array.isArray(response.data.results)) {
          console.log("Paginated response with", response.data.results.length, "items");
          setEvents(response.data.results);
          setHasMore(!!response.data.next);
          setTotalPages(Math.ceil(response.data.count / 12));
        } else if (Array.isArray(response.data)) {
          // Handle non-paginated response (fallback)
          console.log("Non-paginated response with", response.data.length, "items");
          setEvents(response.data);
          setHasMore(false);
          setTotalPages(1);
        } else {
          console.error("Unexpected response format:", response.data);
          setError('Unexpected data format from server');
        }
      } catch (error) {
        console.error('Error fetching events:', error);
        setError('Failed to load events: ' + (error.message || ''));
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [city, page]);

  const handleNextPage = () => {
    if (hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(prevPage => prevPage - 1);
    }
  };

  if (loading && events.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  if (events.length === 0) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography align="center">
          No upcoming events found in {city}.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={4}>
        {events.map(event => (
          <Grid item xs={12} sm={6} md={4} key={event.slug}> 
            <EventCard 
              event={event} 
              city={city}
            />
          </Grid>
        ))}
      </Grid>
      
      {(page > 1 || hasMore) && (
        <Stack 
          direction="row" 
          spacing={2} 
          justifyContent="center" 
          sx={{ mt: 4 }}
        >
          <Button 
            variant="outlined" 
            disabled={page <= 1} 
            onClick={handlePrevPage}
          >
            Previous
          </Button>
          <Typography sx={{ alignSelf: 'center' }}>
            Page {page} of {totalPages}
          </Typography>
          <Button 
            variant="outlined" 
            disabled={!hasMore} 
            onClick={handleNextPage}
          >
            Next
          </Button>
        </Stack>
      )}
    </Box>
  );
}

export default EventList;